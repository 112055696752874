import { useEffect } from 'react';
import { navigate } from "@reach/router"
import React from "react"
const NotFoundPage = () => {
  useEffect(() => {
    navigate('/');
  }, []);
  return (<div></div>);
};
export default NotFoundPage;
